import React from 'react'
import {
  Link,
  graphql,
  useStaticQuery
} from 'gatsby'

import {
  Breadcrumb,
  BreadcrumbItem,
  ContentArea,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout'
import SEO from '../../components/seo'


const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      allDataJson {
        edges {
          node {
            Businesses_and_Organizations {
              name
              url
            }
            Universities {
              name
              url
            }
            Schools_and_Districts {
              name
              url
            }
          }
        }
      }
    }
  `)

  return (
      <Layout>
        <SEO
          title="Browse BSCS’s partners and collaborators"
          description="BSCS works with a range of businesses, organizations, schools, districts, and universities on science education programs."
          canonicalUrl="https://bscs.org/about/partners/"
        />
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link className='inline-anchor' to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              About
            </BreadcrumbItem>
            <BreadcrumbItem>
              Partners
            </BreadcrumbItem>
          </Breadcrumb>
          <PageTitle>Partners &amp; Collaborators</PageTitle>
          <TitleBorder />
          <div className="md:justify-center">
            <div>
              <p>
                BSCS Science Learning works with a range of businesses, organizations, universities, schools, and districts. See below for a sample of our partners and collaborators over the years.
              </p>
            </div>
          </div>
          <div className='flex flex-wrap-reverse mt-3'>
            <div>
              <div>
                <ContentArea size='xl'>
                  <div>
                    <h2>Businesses and Organizations</h2>
                  </div>
                  <div>
                    <div>
                      <ul style={{ listStyleType: 'none' }}>
                        {data.allDataJson.edges[0].node.Businesses_and_Organizations.map((item, index) => {
                          return item.url
                            ?
                              <li
                                style={{ marginBottom: '.25rem' }}
                                key={`business-or-org-${index}`}
                              >
                                <a
                                  className='inline-anchor'
                                  href={ item.url }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  { item.name }
                                  &nbsp;
                                  <sup>
                                    <i
                                      style={{ fontSize: '.65rem' }}
                                      className="fas fa-external-link-alt"
                                    />
                                  </sup>
                                </a>
                              </li>
                            :
                              <li
                                style={{ marginBottom: '.25rem' }}
                                key={`business-or-org-${index}`}
                              >
                                { item.name }
                              </li>
                        })}
                      </ul>
                    </div>
                  </div>
                </ContentArea>
                <ContentArea size='xl'>
                  <div>
                    <h2>Universities</h2>
                  </div>
                  <div>
                    <div>
                      <ul style={{ listStyleType: 'none' }}>
                        {data.allDataJson.edges[0].node.Universities.map((item, index) => {
                          return item.url
                            ?
                              <li
                                style={{ marginBottom: '.25rem' }}
                                key={`university-${index}`}
                              >
                                <a
                                  className='inline-anchor'
                                  href={ item.url }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  { item.name }
                                  &nbsp;
                                  <sup>
                                    <i
                                      style={{ fontSize: '.65rem' }}
                                      className="fas fa-external-link-alt"
                                    />
                                  </sup>
                                </a>
                              </li>
                            :
                              <li
                                style={{ marginBottom: '.25rem' }}
                                key={`university-${index}`}
                              >
                                { item.name }
                              </li>
                        })}
                      </ul>
                    </div>
                  </div>
                </ContentArea>
                <ContentArea size='xl'>
                  <h2>Schools and Districts</h2>
                  <div>
                    <div>
                      <ul style={{ listStyleType: 'none' }}>
                        {data.allDataJson.edges[0].node.Schools_and_Districts.map((item, index) => {
                          return item.url
                            ?
                              <li
                                style={{ marginBottom: '.25rem' }}
                                key={`school-or-district-${index}`}
                              >
                                <a
                                  className='inline-anchor'
                                  href={ item.url }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  { item.name }
                                  &nbsp;
                                  <sup>
                                    <i
                                      style={{ fontSize: '.65rem' }}
                                      className="fas fa-external-link-alt"
                                    />
                                  </sup>
                                </a>
                              </li>
                            :
                              <li
                                style={{ marginBottom: '.25rem' }}
                                key={`school-or-district-${index}`}
                              >
                                { item.name }
                              </li>
                        })}
                      </ul>
                    </div>
                  </div>
                </ContentArea>

              </div>
            </div>
          </div>
        </div>
      </Layout>
  )
}

export default Partners
